import * as React from 'react'
import { Layout } from '../components/Layout'
import { ProductCard } from "../components/ProductCard";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { graphql } from "gatsby";

const CatalogTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicCatalog
  const products = data.allPrismicProducts
  const catalog = pageContent.data || {}

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>

      <div className="Page__Pre bg-primary pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0 serif">{catalog.label}</h1>
          </div>
        </div>
      </div>
      <section className="ProductPage__Card container">
        <div className="Catalog bg-light shadow-sm rounded-3 px-4 py-lg-4 py-3 mb-5">

                {products.nodes.map((product, i) => {
                  return (
                    <ProductCard key={i} product={product} />
                  );
                })}

        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query catalogQuery($uid: String, $lang: String, $id: String) {
    prismicCatalog(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        label
      }
      uid
      id
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    allPrismicProducts(filter: {data: {published_by: {eq: $uid}}}) {
      nodes {
        id
        url
        data {
          title
          cover {
            url
            gatsbyImageData
          }
          author
          categories {
            category
          }
          format
          isbn
          series
          total_pages
          external_link {
            url
          }
          short_description {
            text
          }
          published_by
        }
      }
    }
  }
`

export default withPrismicPreview(CatalogTemplate);

